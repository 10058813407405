import { HashLink as Link } from 'react-router-hash-link'
import { ReactComponent as ReactLogo } from '../../assets/images/logo/logo-black-text.svg'
import { FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa'

const SocialShare = [
  { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/company/monstrey-technologies/' },
  { Social: <FaInstagram />, link: 'https://www.instagram.com/spottherobotdog/' },
  { Social: <FaTwitter />, link: 'https://twitter.com/MonstreyTech/' },
]

export const Footer = () => (
  <div className='footer-style-2 ptb--30 bg_image bg_image--1' data-black-overlay='6'>
    <div className='wrapper plr--50 plr_sm--20'>
      <div className='row align-items-center justify-content-between'>
        <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
          <div className='inner'>
            <div className='logo text-center text-sm-left mb_sm--20'>
              <Link to='/'>
                <ReactLogo />
              </Link>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
          <div className='inner text-center'>
            <ul className='social-share rn-lg-size d-flex justify-content-center liststyle'>
              {SocialShare.map((val, i) => (
                <li key={i}>
                  <a href={`${val.link}`} target='_blank'>
                    {val.Social}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='col-lg-4 col-md-12 col-sm-12 col-12'></div>
      </div>
    </div>
  </div>
)
