import { slickDot } from '../scripts'
import Slider from 'react-slick'
import { ImgWithFallback } from '../../../blocks/Image'

import ThumbNailNode from '../../../assets/images/blog/node-1-thumbnail.jpg'
import ThumbNailVision from '../../../assets/images/blog/vision-thumbnail.jpg'
import ThumbNailLidar from '../../../assets/images/blog/lidar-thumbnail.jpg'
import ThumbNailFunProjects from '../../../assets/images/blog/spot-google-voice.jpg'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const PostList = [
  {
    image: ThumbNailNode,
    title: 'MT Node',
    category: 'Interface',
    id: 'what-node',
  },
  {
    image: ThumbNailVision,
    title: 'Custom payloads',
    category: 'Payload integration',
    id: 'what-payload',
  },
  {
    image: ThumbNailLidar,
    title: 'Improve versatility',
    category: 'Spot improvements',
    id: '/',
  },
  {
    image: ThumbNailFunProjects,
    title: 'Fun Projects',
    category: 'Miscellaneous',
    id: '/',
  },
]

export const What = () => (
  <div className='rn-blog-area pt--120 pb--140 bg_color--1' id='what'>
    <div className='container'>
      <div className='row align-items-end'>
        <div className='col-lg-6'>
          <div className='section-title text-left'>
            <h2>
              <FormattedMessage id='what.title' />
            </h2>
            <p>
              <FormattedMessage id='what.subTitle' />
            </p>
          </div>
        </div>
      </div>
      <div className='row mt--55 mt_sm--30 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0'>
        <div className='col-lg-12'>
          <Slider {...slickDot}>
            {PostList.map((value, i) => (
              <div className='blog blog-style--1' key={i}>
                <div className='thumbnail' style={{ overflow: 'hidden' }}>
                  <div
                    className='image'
                    style={{ height: 300 + 'px', backgroundImage: `url(${value.image})`, backgroundSize: 'cover' }}
                  />
                </div>
                <div className='content'>
                  <p className='blogtype'>{value.category}</p>
                  <h4 className='title'>{value.title}</h4>
                  <div className='blog-btn'>
                    <Link className='rn-btn text-white' to={value.id}>
                      <FormattedMessage id={value.id === '/' ? 'what.soon' : 'what.read'} />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  </div>
)
