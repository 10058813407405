import { ImgWithFallback } from '../../../blocks/Image'
import SpotWorking from '../../../assets/images/spot/spot-prov-vert.jpg'
import { FormattedMessage } from 'react-intl'

export const About = () => (
  <div className='about-area ptb--120 bg_color--5' id='about'>
    <div className='about-wrapper'>
      <div className='container'>
        <div className='row row--35 align-items-center'>
          <div className='col-lg-5'>
            <div className='thumbnail'>
              <ImgWithFallback className='w-100' src={SpotWorking} alt='About Images' />
            </div>
          </div>
          <div className='col-lg-7'>
            <div className='about-inner inner'>
              <div className='section-title'>
                <h2 className='title'>
                  <FormattedMessage id='about.title' />
                </h2>
                <p className='description'>
                  <FormattedMessage id='about.description' />
                </p>
              </div>
              <div className='row mt--30'>
                <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='about-us-list'>
                    <h3 className='title'>
                      <FormattedMessage id='about.left.title' />
                    </h3>
                    <p><FormattedMessage id='about.left.description' /></p>
                  </div>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='about-us-list'>
                    <h3 className='title'><FormattedMessage id='about.right.title' /></h3>
                    <p>
                    <FormattedMessage id='about.right.description.one' />
                    </p>
                    <p>
                    <FormattedMessage id='about.right.description.two' />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
