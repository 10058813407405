import ReactDOM from 'react-dom'
import { useContext } from 'react'
import { IntlProvider } from 'react-intl'

import './index.scss'

import { App } from './pages/app/app'
import error404 from './elements/error404'

import { BrowserRouter, Switch, Route } from 'react-router-dom'

import messages_nl from './lang/nl.json'
import messages_en from './lang/en.json'
import { Observer } from 'mobx-react-lite'
import { appStoreContext, AppStoreProvider } from './pages/app/store'
import { WhatNode } from './pages/what/node'
import { Footer } from './component/footer/Footer'
import { WhatPayload } from './pages/what/payloads'

const messages = {
  nl: messages_nl,
  en: messages_en,
}

const Root = () => {
  const store = useContext(appStoreContext)

  return (
    <Observer>
      {() => (
        <IntlProvider locale={store.getLanguage} messages={messages[store.getLanguage]}>
          <BrowserRouter basename={'/'}>
            <Switch>
              <Route exact path={`${process.env.PUBLIC_URL}/`} component={App} />
              <Route exact path={`${process.env.PUBLIC_URL}/what-node`} component={WhatNode} />
              <Route exact path={`${process.env.PUBLIC_URL}/what-payload`} component={WhatPayload} />
              <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
              <Route component={error404} />
            </Switch>
            <Footer />
          </BrowserRouter>
        </IntlProvider>
      )}
    </Observer>
  )
}

ReactDOM.render(
  <AppStoreProvider>
    <Root />
  </AppStoreProvider>,
  document.getElementById('root'),
)
