import React, { useContext } from 'react'
import { FiPlay, FiBox, FiCpu } from 'react-icons/fi'
import { FormattedMessage } from 'react-intl'
import { appStoreContext } from '../store'

export const Services = () => {
  const store = useContext(appStoreContext)

  const ServiceList = [
    {
      icon: <FiCpu />,
      title: 'service.rd.title',
      description: 'service.rd.description',
    },
    {
      icon: <FiBox />,
      title: 'service.node.title',
      description: 'service.node.description',
    },
    {
      icon: <FiPlay />,
      title: 'service.spot.title',
      description: 'service.spot.description',
    },
  ]

  return (
    <div className='service-area creative-service-wrapper ptb--120 bg_color--1' id='services'>
      <div className='container'>
        <div className='row creative-service'>
          <div className='col-lg-12'>
            <React.Fragment>
              <div className='row'>
                {ServiceList.map((val, i) => (
                  <div className='col-lg-4 col-md-6 col-sm-6 col-12 text-center' key={i}>
                    <div className='service service__style--2'>
                      <div className='icon'>{val.icon}</div>
                      <div className='content'>
                        <h3 className='title'>
                          <FormattedMessage id={val.title} />
                        </h3>
                        <p>
                          <FormattedMessage id={val.description} />
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  )
}
