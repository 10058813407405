import { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ImgWithFallback } from '../../../blocks/Image'
import ContactImage from '../../../assets/images/contact/covid.jpg'
import { appStoreContext } from '../store'

export const ContactForm = () => {
  const intl = useIntl()
  const store = useContext(appStoreContext)

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    message: '',
  })
  const [fieldErrors, setFieldErrors] = useState({})

  const validationRules = {
    name: val => !!val,
    email: val => val && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val),
    message: val => !!val,
  }

  const validate = () => {
    let errors = {}
    let hasErrors = false
    for (let key of Object.keys(inputs)) {
      errors[key] = !validationRules[key](inputs[key])
      hasErrors |= errors[key]
    }
    setFieldErrors(prev => ({ ...prev, ...errors }))
    return !hasErrors
  }

  const handleOnChange = event => {
    event.persist()
    setServerState(prev => ({ ...prev, status: null }))
    setInputs(prev => ({
      ...prev,
      [event.target.id]: event.target.value,
    }))
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (!validate()) {
      return
    }
    setServerState({ submitting: true })
    fetch('https://formspree.io/f/xrgrgrng', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(inputs),
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        handleServerResponse(true, intl.formatMessage({ id: 'contactForm.submit.success' }))
      })
      .catch(response => {
        handleServerResponse(false, response.message)
      })
  }

  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      setFieldErrors({})
      setInputs({
        name: '',
        email: '',
        message: '',
      })
    }
  }

  const renderFieldError = field => {
    if (fieldErrors[field]) {
      return (
        <div className='notification'>
          <FormattedMessage id={`contactForm.error.${field}`} />
        </div>
      )
    }
  }

  return (
    <div id='contact-form' className='rn-contact-us ptb--120 bg_color--5 contact-form--1'>
      <div className='container'>
        <div className='row row--35 align-items-start'>
          <div className='col-lg-6 order-2 order-lg-1'>
            <div className='section-title text-left mb--50'>
              <h2 className='title'>
                <FormattedMessage id='contactForm.title' />
              </h2>
              <p className='description'>
                <FormattedMessage id='contactForm.subTitle' />
              </p>
            </div>
            <div className='form-wrapper'>
              <form noValidate onSubmit={handleSubmit}>
                <input type='hidden' name='_language' value={store.getLanguage} />
                <label htmlFor='name'>
                  <input
                    className={fieldErrors['name'] ? 'error' : ''}
                    required
                    type='text'
                    name='name'
                    id='name'
                    onChange={handleOnChange}
                    value={inputs.name}
                    placeholder={intl.formatMessage({ id: 'contactForm.name' })}
                  />
                  {renderFieldError('name')}
                </label>

                <label htmlFor='email'>
                  <input
                    className={fieldErrors['email'] ? 'error' : ''}
                    required
                    type='text'
                    name='email'
                    id='email'
                    onChange={handleOnChange}
                    value={inputs.email}
                    placeholder={intl.formatMessage({ id: 'contactForm.email' })}
                  />
                  {renderFieldError('email')}
                </label>

                <label htmlFor='message'>
                  <textarea
                    className={fieldErrors['message'] ? 'error' : ''}
                    required
                    type='text'
                    id='message'
                    name='message'
                    onChange={handleOnChange}
                    value={inputs.message}
                    placeholder={intl.formatMessage({ id: 'contactForm.message' })}
                  />
                  {renderFieldError('message')}
                </label>

                <button
                  disabled={serverState.submitting}
                  className='rn-button-style--2 btn-solid'
                  type='submit'
                  value='submit'
                  name='submit'
                  id='mc-embedded-subscribe'
                >
                  <FormattedMessage id='contactForm.submit' />
                </button>
              </form>
            </div>
          </div>
          <div className='col-lg-6 order-1 order-lg-2'>
            <div className='thumbnail mb_md--30 mb_sm--30'>
              <ImgWithFallback src={ContactImage} />
            </div>
          </div>
        </div>
        <div className='notification'>{serverState.status?.msg}</div>
      </div>
    </div>
  )
}
