import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import ModalVideo from 'react-modal-video'
import { Fragment, useContext, useState } from 'react'
import PageHelmet from '../../component/common/Helmet'
import { Header } from '../../component/header/Header'
import { appStoreContext } from '../app/store'
import { ScrollToTopController } from '../../infrastructure/router'
import { ImgWithFallback } from '../../blocks/Image'

import NodeOne from '../../assets/images/blog/node-1.jpg'
import NodeTwo from '../../assets/images/blog/node-2.jpg'
import NodeTree from '../../assets/images/blog/node-3.jpg'
import PortfolioGalleryPopup from '../../elements/portfolio/PortfolioGalleryPopup'
import { FormattedMessage } from 'react-intl'

const SocialShare = [
  { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
  { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
  { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
  { Social: <FaTwitter />, link: 'https://twitter.com/' },
]

export const WhatNode = () => {
  const [isOpen, setIsOpen] = useState(false)
  const store = useContext(appStoreContext)

  store.setStickyHeader(true)

  return (
    <Fragment>
      <ScrollToTopController />
      <PageHelmet pageTitle='MT Node' />
      <Header color='dark' />
      <div className='rn-portfolio-details ptb--120 bg_color--1' id='what'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='portfolio-details'>
                <div className='inner'>
                  <h2>
                    <FormattedMessage id='node.title' />
                  </h2>
                  <p className='subtitle'>
                    <FormattedMessage id='node.subTitle' />
                  </p>
                  <h3>
                    <FormattedMessage id='node.hardware.title' />
                  </h3>
                  <p>
                    <FormattedMessage id='node.hardware.text.one' />
                  </p>
                  <p>
                    <FormattedMessage id='node.hardware.text.two' />
                  </p>
                  <p>
                    <FormattedMessage id='node.hardware.text.three' />
                  </p>
                  <p>
                    <FormattedMessage id='node.hardware.text.four' />
                  </p>
                  <h3>
                    <FormattedMessage id='node.software.title' />
                  </h3>
                  <p>
                    <FormattedMessage id='node.software.text.one' />
                  </p>
                  <p>
                    <FormattedMessage id='node.software.text.two' />
                  </p>

                  <div className='portfolio-thumb-inner'>
                    <div className='thumb mb--30'>
                      <ImgWithFallback src={NodeOne} />
                    </div>

                    <div className='thumb mb--30'>
                      <ImgWithFallback src={NodeTwo} />
                    </div>

                    <div className='thumb'>
                      <ImgWithFallback src={NodeTree} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
