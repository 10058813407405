import { FormattedMessage } from "react-intl"

const SlideList = [
  {
    textPosition: 'text-center',
    category: '',
    title: 'slider.one.title',
    description: 'slider.one.description',
    buttonText: 'slider.one.button',
    buttonLink: '#contact',
  },
] 

export const Slider = () => (
  <div className='slider-activation slider-creative-agency' id='home'>
    <div className='bg_image bg_image--26' data-black-overlay='6'>
      {SlideList.map((value, index) => (
        <div
          className='slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center'
          key={index}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className={`inner ${value.textPosition}`}>
                  {value.category ? <span>{value.category}</span> : ''}
                  {value.title ? <h1 className='title theme-gradient'><FormattedMessage id={value.title} /></h1> : ''}
                  {value.description ? <p className='description'><FormattedMessage id={value.description} /></p> : ''}
                  {value.buttonText ? (
                    <div className='slide-btn'>
                      <a className='rn-button-style--2 btn-primary-color' href={`${value.buttonLink}`}>
                      <FormattedMessage id={value.buttonText} />
                      </a>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)
