import { HashLink as Link } from 'react-router-hash-link'
import { FiX, FiMenu } from 'react-icons/fi'
import Scrollspy from 'react-scrollspy'
import { ReactComponent as ReactLogo } from '../../assets/images/logo/logo-black-text.svg'
import { FormattedMessage } from 'react-intl'
import { appStoreContext } from '../../pages/app/store'
import { useContext, useEffect, useState } from 'react'

export const Header = ({ color }) => {
  const store = useContext(appStoreContext)
  const [colorClassName, setColorClassName] = useState('color-white')

  const renderLogo = () => {
    switch (color) {
      case 'white':
        return (
          <div style={{ overflow: 'hidden', width: '280px' }}>
            <ReactLogo className='logo-dark' alt='Logo Images' />
          </div>
        )
      case 'dark':
        return (
          <div style={{ overflow: 'hidden', width: '280px' }}>
            <ReactLogo className='logo-white' alt='Logo Images' />
          </div>
        )
      default:
        return (
          <div style={{ overflow: 'hidden', width: '280px' }}>
            <ReactLogo className='logo-1' alt='Logo Images' />
            <ReactLogo className='logo-2' alt='Logo Images' />
          </div>
        )
    }
  }
  const menuTrigger = () => {
    document.querySelector('.header-wrapper').classList.toggle('menu-open')
  }
  const CLoseMenuTrigger = () => {
    document.querySelector('.header-wrapper').classList.remove('menu-open')
  }

  window.addEventListener('scroll', function () {
    var value = window.scrollY
    if (value > 100) {
      document.querySelector('.header--fixed').classList.add('sticky')
    } else {
      document.querySelector('.header--fixed').classList.remove('sticky')
    }
  })

  const elements = document.querySelectorAll('.has-dropdown > a, .has-dropdown > .link')
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector('.submenu').classList.toggle('active')
        this.classList.toggle('open')
      }
    }
  }

  useEffect(() => {
    var colorClass = 'color-white'
    switch (color) {
      case 'white':
        colorClass = 'color-white'
        break
      case 'dark':
        colorClass = 'color-black'
        break
      default:
        colorClass = 'default-color'
        break
    }
    setColorClassName(colorClass)
  }, [color])

  return (
    <header className={`header-area formobile-menu header--fixed ${colorClassName}`}>
      <div className='header-wrapper' id='header-wrapper'>
        <div className='header-left'>
          <div className='logo'>
            <a href='/'>{renderLogo()}</a>
          </div>
        </div>
        <div className='header-right'>
          <nav className='mainmenunav d-lg-block'>
            <Scrollspy
              className='mainmenu'
              items={['home', 'services', 'about', 'what', 'contact']}
              currentClassName='is-current'
              offset={-200}
            >
              <li>
                <Link to='/#home'>
                  <FormattedMessage id='header.home' />
                </Link>
              </li>
              <li>
                <Link to='/#services'>
                  <FormattedMessage id='header.services' />
                </Link>
              </li>
              <li>
                <Link to='/#about'>
                  <FormattedMessage id='header.about' />
                </Link>
              </li>
              <li className='has-dropdown'>
                <Link to='/#what'>
                  <FormattedMessage id='header.what' />
                </Link>
                <ul className='submenu'>
                  <li>
                    <Link to='/what-node'>
                      <FormattedMessage id='header.what.node' />
                    </Link>
                  </li>
                  <li>
                    <Link to='/what-payload'>
                      <FormattedMessage id='header.what.payload' />
                    </Link>
                  </li>
                  {/* <li>
                    <Link to='/what-improvements'>
                      <FormattedMessage id='header.what.improvements' />
                    </Link>
                  </li>
                  <li>
                    <Link to='/what-fun'>
                      <FormattedMessage id='header.what.fun' />
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li>
                <Link to='/#contact'>
                  <FormattedMessage id='header.contact' />
                </Link>
              </li>
              <li className='has-dropdown'>
                <div className='link'>
                  <FormattedMessage id='header.language' />
                </div>
                <ul className='submenu end'>
                  <li>
                    <div className='link' onClick={() => store.setLanguage('nl')}>
                      Nederlands
                    </div>
                  </li>
                  <li>
                    <div className='link' onClick={() => store.setLanguage('en')}>
                      English
                    </div>
                  </li>
                </ul>
              </li>
            </Scrollspy>
          </nav>
          {/*  <div className='header-btn'>
            <a className='rn-btn' href='https://client.monstrey.tech'>
              <span>
                <FormattedMessage id='header.client' />
              </span>
            </a>
          </div> */}
          <div className='humberger-menu d-block d-lg-none pl--20'>
            <span onClick={menuTrigger} className='menutrigger text-white'>
              <FiMenu />
            </span>
          </div>
          <div className='close-menu d-block d-lg-none'>
            <span onClick={CLoseMenuTrigger} className='closeTrigger'>
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  )
}
