import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import ModalVideo from 'react-modal-video'
import { Fragment, useContext, useState } from 'react'
import PageHelmet from '../../component/common/Helmet'
import { Header } from '../../component/header/Header'
import { appStoreContext } from '../app/store'
import { ScrollToTopController } from '../../infrastructure/router'
import { ImgWithFallback } from '../../blocks/Image'

import PayloadOne from '../../assets/images/blog/payload-1.jpg'
import PayloadTwo from '../../assets/images/blog/payload-2.jpg'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

export const WhatPayload = () => {
  const [isOpen, setIsOpen] = useState(false)
  const store = useContext(appStoreContext)

  store.setStickyHeader(true)

  return (
    <Fragment>
      <ScrollToTopController />
      <PageHelmet pageTitle='Payloads' />
      <Header color='dark' />
      <div className='rn-portfolio-details ptb--120 bg_color--1' id='what'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='portfolio-details'>
                <div className='inner'>
                  <h2>
                    <FormattedMessage id='payload.title' />
                  </h2>
                  <p className='subtitle'>
                    <FormattedMessage id='payload.subTitle' />
                  </p>
                  <h3>
                    <FormattedMessage id='payload.what.title' />
                  </h3>
                  <p>
                    <FormattedMessage id='payload.what.one' values={{ link: <Link to='what-node'>MT Node</Link> }} />
                  </p>
                  <div className='paragraph'>
                    <FormattedMessage id='payload.what.list.title' />
                    <ul>
                      <li>
                        <FormattedMessage id='payload.what.list.camera' />
                      </li>
                      <li>
                        <FormattedMessage id='payload.what.list.sensors' />
                      </li>
                      <li>
                        <FormattedMessage
                          id='payload.what.list.interfaces'
                          values={{ link: <Link to='what-node'>MT Node</Link> }}
                        />
                      </li>
                    </ul>
                  </div>
                  <h3>
                    <FormattedMessage id='payload.mt.title' />
                  </h3>
                  <p>
                    <FormattedMessage id='payload.mt.one' values={{ link: <Link to='what-node'>MT Node</Link> }} />
                  </p>
                  <div className='paragraph'>
                    <FormattedMessage
                      id='payload.mt.list.title'
                      values={{ link: <Link to='what-node'>MT Node</Link> }}
                    />
                    <ul>
                      <li>
                        <FormattedMessage id='payload.mt.list.usb' />
                      </li>
                      <li>
                        <FormattedMessage id='payload.mt.list.bl' />
                      </li>
                      <li>
                        <FormattedMessage id='payload.mt.list.wifi' />
                      </li>
                      <li>
                        <FormattedMessage id='payload.mt.list.eth' />
                      </li>
                    </ul>
                  </div>
                  <div className='portfolio-thumb-inner'>
                    <div className='thumb mb--30'>
                      <ImgWithFallback src={PayloadOne} />
                    </div>

                    <div className='thumb'>
                      <ImgWithFallback src={PayloadTwo} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
