import { Fragment, useContext } from 'react'
import { Slider as HomeSlider } from './elements/slider'
import { Services as HomeServices } from './elements/services'
import { About as HomeAbout } from './elements/about'
import { What as HomeWhat } from './elements/what'
import { ContactForm as HomeContactForm } from './elements/contact-form'
import ScrollToTop from 'react-scroll-up'
import { FiChevronUp } from 'react-icons/fi'
import Helmet from '../../component/common/Helmet'
import { Header } from '../../component/header/Header'
import { appStoreContext } from './store'
import { ScrollToTopController } from '../../infrastructure/router'

export const App = () => {
  const store = useContext(appStoreContext)
  store.setStickyHeader(false)
  return (
    <Fragment>
      <ScrollToTopController />
      <Helmet pageTitle='Home' />
      <Header headerPosition='header--transparent' color='color-white' logo='logo-light' />
      <HomeSlider />
      <HomeServices />
      <HomeAbout />
      <HomeWhat />
      <div id='contact'>
        <HomeContactForm />
      </div>
      <div className='backto-top'>
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
    </Fragment>
  )
}
