import { createContext } from 'react'
import { useLocalObservable } from 'mobx-react-lite'

export const appStoreContext = createContext()

export const AppStoreProvider = ({ children }) => {

  const isAvailable = (lang, defaultLang = 'en') => {
    return ['nl', 'en'].includes(lang) ? lang : defaultLang
  }

  const localStorageLanguage = () => {
    return localStorage.getItem('language')
  }

  const store = useLocalObservable(() => ({
    activeLanguage: null,
    stickyHeader: false,
    setLanguage: id => {
      store.activeLanguage = id
      localStorage.setItem('language', id)
    },
    setStickyHeader: sticky => {
      store.stickyHeader = sticky
    },
    get getStickyHeader(){
      return store.stickyHeader
    },
    get getLanguage() {
      return isAvailable(
        store.activeLanguage || localStorageLanguage() || (navigator.language || navigator.userLanguage).split('-')[0],
      )
    },
  }))

  return <appStoreContext.Provider value={store}>{children}</appStoreContext.Provider>
}
